export const fbcqxxColumn = [
  {
    prop: "xmbh",
    label: "项目编号",
    width: 100,
  },
  {
    prop: "xmmc",
    label: "项目名称",
  },
  {
    prop: "gpjg",
    label: "挂牌总价",
  },
  {
    prop: "flowAreaMu",
    label: "面积",
    width: 70,
    template: true,
  },
  {
    prop: "lznx",
    label: "年限",
    width: 70,
  },
  {
    prop: "lzlx",
    label: "交易类型",
    width: 70,
    template: true,
  },
  {
    prop: "fbsj",
    label: "发布时间",
    width: 90,
  },
  {
    prop: "cqlxbh",
    label: "产权类型",
    width: 120,
    template: true,
  },
  {
    prop: "cjTime",
    label: "成交时间",
    width: 120,
    template: true,
  },
  {
    prop: "cjzj",
    label: "成交金额(元)",
    width: 120,
    template: true,
  },
  {
    prop: "jjzt",
    label: "承接人",
    width: 100,
    template: true,
  },
  {
    prop: "operation",
    label: "操作",
    width: 250,
    template: true,
  },
];
export const spColumn = [
  {
    prop: "xmbh",
    label: "项目编号",


  },
  {
    prop: "xmmc",
      template: true,
    label: "标题",
  },
  {
    prop: "cjTime",
    label: "成交时间",

  },
  {
    prop: "flowAreaMu",
    label: "面积",
    template: true,

  },
  {
    prop: "lznx",
    label: "年限",
    template: true,

  },
  {
    prop: "cjzj",
    label: "金额",

    template: true,
  },
  {
    prop: "crf",
    label: "流出方",
  },
];
// 没有集体负责人的
export const sqProps = [
  {
    prop: "xmbh",
    label: "项目编号",
    width: 200,
    template: true,
  },
  {
    prop: "xmmc",
    label: "标题",
    template: true,
    width: 450,
    tooltip:false,
  },
  {
    prop: "xmdz",
    label: "所在地址",
    template: true,
  },
  {
    prop: "phone",
    label: "村集体负责人联系方式",
    width: 200,
    template: true,
    tooltip:false,
  },
  {
    prop: "operation",
    label: "操作",
    width: 250,
    template: true,
  },



];
// 有集体负责人的
export const sqProps2 = [
  {
    prop: "xmbh",
    label: "项目编号",
    width: 200,

  },
  {
    prop: "xmmc",
    label: "标题",
    template: true,
    width: 450,
  },
  {
    prop: "cjTime",
    label: "成交时间",

  },
  {
    prop: "flowAreaMu",
    label: "面积",

    template: true,
  },
  {
    prop: "lznx",
    label: "年限",
    template: true,
  },
  {
    prop: "cjzj",
    label: "金额",
    template: true,
  },
  {
    prop: "crf",
    label: "流出方",
    width: 200,
  },
  {
    prop: "operation",
    label: "操作",
    width: 250,
    template: true,
  },

];
export const WriteProps = [
  {
    prop: "xmbh",
    label: "项目编号",
    width: 100,

  },
  {
    prop: "xmmc",
    label: "标题",
    template: true,
  },
  {
    prop: "cjTime",
    label: "成交时间",
    width: 100,
  },
  {
    prop: "flowAreaMu",
    label: "面积",
    width: 70,
    template: true,
  },
  {
    prop: "lznx",
    label: "年限",
    template: true,
    width: 70,

  },
  {
    prop: "cjzj",
    label: "金额",
    template: true,
    width: 70,

  },
  {
    prop: "crf",
    label: "流出方",
  },
  {
    prop: "content",
    label: "退回原因",
  },
  {
    prop: "operation",
    label: "操作",
    width: 250,
    template: true,
  },

];
export const resubmitProps = [
  {
    prop: "xmbh",
    label: "项目编号",
    width: 100,
  },
  {
    prop: "xmmc",
    label: "标题",
  },
  {
    prop: "cjTime",
    label: "成交时间",
    width: 100,
  },
  {
    prop: "flowAreaMu",
    label: "面积",
    width: 70,
    template: true,
  },
  {
    prop: "lznx",
    label: "年限",
    width: 70,
  },
  {
    prop: "cjzj",
    label: "金额",
    width: 70,
    template: true,
  },
  {
    prop: "crf",
    label: "流出方",
  },
  {
    prop: "operation",
    label: "操作",
    width: 250,
    template: true,
  },
];
